import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

import automated from "../../assets/images/automated.png";
import ChasingActivityImage1 from "../../assets/images/ChasingActivityImage1.png";
import ChasingActivityImage2 from "../../assets/images/ChasingActivityImage2.png";
import ChasingActivityImage3 from "../../assets/images/ChasingActivityImage3.png";

import StartNow_image from "../../assets/images/StartNow.png";
import DemoVideo_image from "../../assets/images/DemoVideo.png";
import YoutubeIcon from "../../assets/images/YoutubeIcon.png";
import activities from "../../assets/images/activities.png";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "0px 30px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  wrapper2: {
    padding: "0px 50px",
    [theme.breakpoints.down("xs")]: {
      padding: 20,
    },
  },
  banner: {
    background: "#E3EBFC",
    borderRadius: 10,
    padding: 50,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      marginTop: 10,
    },
  },

  banner_image: {
    flex: 1,
    padding: 30,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  banner_content: {
    flex: 1,
    padding: 30,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: 20,
    },
  },
  banner_button: {
    padding: "13px 35px  !important",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
    },
  },
  banner_content_text: {
    fontSize: 24,
    marginBottom: 30,
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  banner_headline: {
    fontWeight: 600,
    marginBottom: 80,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 40,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 20,
      fontSize: "2rem",
    },
  },
  play: {
    color: "#0B4295",
    marginLeft: 10,
    padding: "13px 35px  !important",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      border: "1px solid #FD5E47",
    },
  },
  activity_wrapper: {
    padding: "60px 120px",
    [theme.breakpoints.down("xs")]: {
      padding: "20px 0px",
    },
  },

  activity_box: {
    textAlign: "center",
  },
  activity_image: {
    maxWidth: "100%",
    padding: "0px 40px 15px 40px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "45%",
    },
  },

  margin10: {
    marginBottom: 30,
  },
  activity_wrapper2: {
    background: "#E3EBFC",
    borderRadius: 10,
    padding: "40px 80px",
    [theme.breakpoints.down("xs")]: {
      padding: 20,
    },
  },
  image: {
    maxWidth: "80%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      padding: 30,
    },
  },
  demo: {
    background: "#E3EBFC",
    borderRadius: 10,
    padding: "60px 70px",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      padding: 20,
      width: "100%",
    },
  },
  demo_image: {
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {},
  },
  demo_headline: {
    marginBottom: 50,
  },
  activity2: {
    display: "flex",
    alignItems: "center",
  },
  improve: {
    textAlign: "center",
    marginTop: 40,
    marginBottom: 40,
  },
  improve_image: {
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "60%",
    },
  },
  center_button: {
    textAlign: "center",
    marginTop: 40,
  },
  youtube: {
    position: "relative",
  },
  YoutubeIcon: {
    position: "absolute",
    left: "40%",
    top: "40%",
    [theme.breakpoints.down("xs")]: {
      left: "15%",
      top: "0%",
    },
  },
  hide_br: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  marginStyle: {
    [theme.breakpoints.down("xs")]: {
      margin: "0 !important",
    },
  },
}));

const ActivityTracking = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <CssBaseline /> */}
      <Container maxWidth="lg">
        <Grid container className={classes.wrapper}>
          <Grid item lg={12} md={12}>
            <div className={classes.banner}>
              <div className={classes.banner_image}>
                <img src={automated} alt="" />
              </div>
              <div className={classes.banner_content}>
                <Typography variant="h3" className={classes.banner_headline}>
                  Automated
                </Typography>
                <Typography className={classes.banner_content_text}>
                  With KinderCubby, you can automate the activities that you
                  choose to deploy. Automatically record, learn, and choose new
                  educational interventions that are proven to enhance a child’s
                  experience.
                </Typography>
                <div>
                  <Link to="/try-it-free">
                    <Button
                      variant="contained"
                      color="primary"
                      className={`${classes.banner_button} button`}
                    >
                      Try it Free
                    </Button>
                  </Link>
                  <Button
                    // variant="contained"
                    color="secondary"
                    className={`${classes.play} button`}
                    startIcon={<PlayCircleOutlineIcon />}
                  >
                    Watch In Action
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg">
        <Grid container spacing={3} className={classes.activity_wrapper}>
          <Grid item xs={12}>
            <Typography variant="h4">
              No more chasing after kids to <br />
              record their activity
            </Typography>
          </Grid>

          <Grid item lg={4} md={4} className={classes.activity_box}>
            <img
              className={classes.activity_image}
              src={ChasingActivityImage1}
              alt=""
            />

            <div>
              <Typography variant="h5">Activity Feed</Typography>
              <Typography variant="subtitle1">
                Keep track of an activity feed to see what each child has
                participated in and needs to accomplish throughout the day.
              </Typography>
            </div>
          </Grid>

          <Grid item lg={4} md={4} className={classes.activity_box}>
            <img
              className={classes.activity_image}
              src={ChasingActivityImage2}
              alt=""
            />

            <div>
              <Typography variant="h5">Photos & Videos</Typography>
              <Typography variant="subtitle1">
                Snap a photo or video of the child and send it to their parents
                to update them day in and day out.
              </Typography>
            </div>
          </Grid>

          <Grid item lg={4} md={4} className={classes.activity_box}>
            <img
              className={classes.activity_image}
              src={ChasingActivityImage3}
              alt=""
            />

            <div>
              <Typography variant="h5">Activity Reports</Typography>
              <Typography variant="subtitle1">
                Automatically generate an activity report to see what each child
                accomplished during the day and what learning outcomes they
                achieved.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg">
        <div className={classes.wrapper2}>
          <Grid container spacing={3} className={classes.activity_wrapper2}>
            <Grid item xs={12}>
              <Typography variant="h4">
                Activities that we are keeping track of
              </Typography>
            </Grid>
            <div className={classes.activity2}>
              <Grid item lg={6} md={6}>
                <img className={classes.image} src={activities} alt="" />
              </Grid>
              <Grid item lg={6} md={6}>
                <Typography variant="subtitle1" className={classes.margin10}>
                  <strong>Food: </strong>
                  Learn about what your child eats for lunch to ensure a healthy
                  lifestyle.
                </Typography>
                <Typography variant="subtitle1" className={classes.margin10}>
                  <strong>Nap Time: </strong>
                  Monitor whether each child takes a nap to rejuvenate
                  themselves for the rest of the day.
                </Typography>
                <Typography variant="subtitle1" className={classes.margin10}>
                  <strong>Reading: </strong> Track how many words and books a
                  child manages to read during the day to assess reading
                  comprehension.
                </Typography>
                <Typography variant="subtitle1" className={classes.margin10}>
                  <strong>Playing: </strong> Provide information about who and
                  what the child play with to assess proper psychosocial
                  development.
                </Typography>
                <Typography variant="subtitle1" className={classes.margin10}>
                  <strong>Incident: </strong> Report any behavioral incidents
                  that a child may accrue during daycare and report it to their
                  parents as needed.
                </Typography>
                <Typography variant="subtitle1" className={classes.margin10}>
                  <strong>Kid's health: </strong> Assess new signs and symptoms
                  of each child to determine whether they are sick and need to
                  be released home.
                </Typography>
                <Typography variant="subtitle1" className={classes.margin10}>
                  <strong>Kid's activity: </strong> Ensure kids are receiving
                  adequate amounts of physical activity outside to develop their
                  mobility and strength.
                </Typography>
              </Grid>
            </div>
          </Grid>
        </div>
      </Container>
      <Container maxWidth="lg">
        <Grid container spacing={3} className={`${classes.activity_wrapper}`}>
          <Grid item xs={12}>
            <Typography variant="h4">
              Improve the quality of your program &{" "}
              <br className={classes.hide_br} /> give parents the information
              they need
            </Typography>
            <Typography variant="h6" className={classes.marginStyle}>
              Become a top-performing daycare center with KinderCubby. Track and
              share a variety of activities with parents to provide them with
              information about their child’s social, educational, and physical
              development.
            </Typography>
            <div className={classes.improve}>
              <img
                src={StartNow_image}
                className={classes.improve_image}
                alt=""
              />
            </div>
            <div className={classes.center_button}>
              <Link to="/try-it-free">
                <Button
                  variant="contained"
                  color="primary"
                  className={`${classes.banner_button} button`}
                >
                  Start Now
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg">
        <Grid container className={classes.wrapper}>
          <Grid item lg={12} md={12}>
            <div className={classes.demo}>
              <Typography variant="h4" className={classes.demo_headline}>
                KinderCubby activity tracking demo
              </Typography>
              <div className={classes.youtube}>
                <div className={classes.YoutubeIcon}>
                  <img src={YoutubeIcon} alt="" />
                </div>
                <img
                  className={classes.demo_image}
                  src={DemoVideo_image}
                  alt=""
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg">
        <Grid container spacing={3} className={`${classes.activity_wrapper}`}>
          <Grid item lg={12} md={12}>
            <Typography variant="h4">
              Get better insight & actionable information <br /> with
              KinderCubby
            </Typography>
            <div className={classes.center_button}>
              <Link to="/try-it-free">
                <Button
                  variant="contained"
                  color="primary"
                  className={`${classes.banner_button} button`}
                >
                  Yes! Let’s try it.
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ActivityTracking;
