import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import Success from "../../assets/images/Success@2x.png";
import IconActivity from "../../assets/images/IconActivity.png";
import IconActivityReports from "../../assets/images/IconActivityReports.png";
import IconAdministrativeReport from "../../assets/images/IconAdministrativeReport.png";
import IconAssessment from "../../assets/images/IconAssessment.png";
import IconAttendence from "../../assets/images/IconAttendence.png";
import IconAutomatedInvoice from "../../assets/images/IconAutomatedInvoice.png";
import IconChildLedgers from "../../assets/images/IconChildLedgers.png";
import IconDataManagement from "../../assets/images/IconDataManagement.png";
import IconDedicatedApp from "../../assets/images/IconDedicatedApp.png";
import IconDigitalReport from "../../assets/images/IconDigitalReport.png";
import IconDistanceLearning from "../../assets/images/IconDistanceLearning.png";
import IconIncidentReports from "../../assets/images/IconIncidentReports.png";
import IconLessonPlaning from "../../assets/images/IconLessonPlaning.png";
import IconMonitorChildsGrowth from "../../assets/images/IconMonitorChildsGrowth.png";
import IconOnlinePayment from "../../assets/images/IconOnlinePayment.png";
import IconPhotoAndVideos from "../../assets/images/IconPhotoAndVideos.png";
import IconPickUp from "../../assets/images/IconPickUp.png";
import IconRevenueSummary from "../../assets/images/IconRevenueSummary.png";
import IconShareEveryMoment from "../../assets/images/IconShareEveryMoment.png";
import IconInstantMessaging from "../../assets/images/IconInstantMessaging.png";
import IconStaffManagement from "../../assets/images/IconStaffManagement.png";
import green_checked_icon from "../../assets/images/green_checked_icon.svg";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import SliderImage from "../../components/SliderImage";

const useStyles = makeStyles((theme) => ({
  item5: {
    order: 5,
    [theme.breakpoints.down("sm")]: {
      order: 6,
    },
  },
  item6: {
    order: 6,
    [theme.breakpoints.down("sm")]: {
      order: 7,
    },
  },
  item7: {
    order: 7,
    [theme.breakpoints.down("sm")]: {
      order: 5,
    },
  },

  wrapper: {
    padding: "0px 30px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  wrapper2: {
    padding: "60px 30px 0 30px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },

  banner_button: {
    padding: "13px 35px  !important",

    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      padding: "7px 16px  !important",
    },
  },

  activity_wrapper: {
    padding: "60px 120px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 0px",
    },
  },
  activity_wrapper2: {
    padding: "30px 120px 0 120px",
    [theme.breakpoints.down("md")]: {
      padding: "30px 80px 0 80px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px 0px 0 0",
    },
  },
  activity_wrapper3: {
    padding: "60px 120px 0 120px",
    [theme.breakpoints.down("md")]: {
      padding: "60px 80px 0 80px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "30px 0px 0 0",
    },
  },

  background_style: {
    background: "#E3EBFC",
    borderRadius: "10px",
    padding: "60px",
    [theme.breakpoints.down("sm")]: {
      padding: "30px 30px",
    },
  },

  activity_box: {
    textAlign: "center",
  },

  paper: {
    padding: theme.spacing(1),
    marginBottom: "20px",
    textAlign: "center",
    boxShadow: "1px 1px 5px 3px #eee",
  },

  tab_headline: {
    fontWeight: 600,
    fontSize: "14px",
    color: "#454141",
    marginBottom: "15px",
  },
  tab_headline2: {
    fontWeight: 600,
    fontSize: "18px",
    color: "#454141",
    marginBottom: "15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },
  tab_text: {
    fontSize: "12px",
    lineHeight: "15px",
    color: "#454141",
    marginBottom: "15px",
  },

  center_button: {
    textAlign: "center",
    marginTop: 40,
  },

  activity_image: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70px",
    width: "70px",
    margin: "20px auto",
    borderRadius: "50%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  activity_image_blue: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70px",
    width: "70px",
    padding: "15px",
    margin: "20px auto",
    backgroundColor: "#8F9FCC",
    borderRadius: "50%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.down("md")]: {
      height: "60px",
      width: "60px",
    },
  },
  activity_image_none: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70px",
    width: "70px",
    // padding: "15px",
    margin: "20px auto",
    borderRadius: "50%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.down("md")]: {
      height: "60px",
      width: "60px",
    },
  },
  activity_image_green: {
    backgroundColor: "#A2D399",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70px",
    width: "70px",
    padding: "15px",
    margin: "20px auto",
    borderRadius: "50%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.down("md")]: {
      height: "60px",
      width: "60px",
    },
  },
  activity_image_red: {
    backgroundColor: "#EF8787",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70px",
    width: "70px",
    padding: "15px",
    margin: "20px auto",
    borderRadius: "50%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.down("md")]: {
      height: "60px",
      width: "60px",
    },
  },
  activity_image_orange: {
    backgroundColor: "#EAA17F",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70px",
    width: "70px",
    padding: "15px",
    margin: "20px auto",
    borderRadius: "50%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.down("md")]: {
      height: "60px",
      width: "60px",
    },
  },
  tab_grid_padding: {
    paddingTop: "80px !Important",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "12px !Important",
    },
  },
  center: {
    textAlign: "center",
  },
  margin_left_20: {
    marginLeft: 20,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 3,
    },
  },

  grid_padding_bottom_xs: {
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "0px !Important",
    },
  },
  grid_padding_top_xs: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0px !Important",
    },
  },
  icon_style: {
    position: "relative",
    top: "7px",
    left: "4px",
  },
  tab_headline3: {
    fontWeight: 600,
    fontSize: "18px",
    color: "#F26522",
    marginBottom: "25px",
    textAlign: "center",
  },
  h4: {
    marginBottom: 0,
  },

  auto_margin: {
    margin: "20px auto 40px auto",
  },
  slider_text1: {
    padding: "50px 10px  0px 10px",
  },
  slider_text2: {
    padding: "0px 10px  50px 10px",
  },
  iconStyle: {
    width: "40px",
    [theme.breakpoints.down("md")]: {
      width: "30px",
    },
  },
  successIconStyle: {
    width: "90px",
    [theme.breakpoints.down("md")]: {
      width: "60px",
    },
  },
  detailsMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "5px !important",
    },
  },
  hide_br: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const FeatureOverviews = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Container maxWidth="lg">
        <Grid container className={`${classes.wrapper}`}>
          <Grid item xs={12} className={classes.background_style}>
            <Typography variant="h4">
              Features for modern
              <br /> childcare management
            </Typography>
            <Typography variant="h6" className={classes.detailsMargin}>
              We offer childcare centers with dozens of contemporary features
              that are built
              <br /> to help your center succeed. Read below to learn more about
              our offerings.
            </Typography>

            <div className={classes.center_button}>
              <Link to="/try-it-free">
                <Button
                  variant="contained"
                  color="primary"
                  className={`${classes.banner_button} button`}
                >
                  Try It Free
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
          justify="center"
          alignItems="center"
          className={classes.activity_wrapper3}
        >
          <Grid item xs={12} className={classes.item1}>
            <Typography variant="h4">
              Let the system monitor activities
              <br /> Focus on what really matters
            </Typography>
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            className={`${classes.activity_box} ${classes.item2}`}
          >
            <Typography variant="h5" style={{ textAlign: "start" }}>
              Attendance & check-in
            </Typography>
            <Typography variant="subtitle1" style={{ textAlign: "start" }}>
              Conduct attendance securely and efficiently with our digital tool,
              allowing you to automatically update records and sign-in your kids
              with ease.
            </Typography>
            <Link to="/attendance-and-check-ins">
              <Typography
                className={classes.tab_headline2}
                style={{ textAlign: "start" }}
              >
                Learn More{" "}
                <span className={classes.icon_style}>
                  <ArrowForwardIcon />
                </span>
              </Typography>
            </Link>
          </Grid>

          <Grid
            item
            lg={3}
            md={3}
            sm={4}
            xs={8}
            className={`${classes.item3} ${classes.grid_padding_bottom_xs}`}
          >
            <Paper className={classes.paper}>
              <div className={classes.activity_image_none}>
                <img
                  src={green_checked_icon}
                  alt=""
                  className={classes.successIconStyle}
                />
              </div>
              <div>
                <Typography className={classes.tab_headline}>
                  Contactless check-in
                </Typography>
                <Typography className={classes.tab_text}>
                  Use a digital device to check-in.
                </Typography>
              </div>
            </Paper>

            <Paper className={classes.paper}>
              <div className={classes.activity_image_red}>
                <img
                  src={IconAttendence}
                  alt=""
                  className={classes.iconStyle}
                />
              </div>

              <div>
                <Typography className={classes.tab_headline}>
                  Attendace Report
                </Typography>
                <Typography className={classes.tab_text}>
                  Know who is present and who is missing.
                </Typography>
              </div>
            </Paper>
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={4}
            xs={8}
            className={`${classes.item4} ${classes.grid_padding_top_xs}`}
          >
            <Paper className={classes.paper}>
              <div className={classes.activity_image_blue}>
                <img src={IconPickUp} alt="" className={classes.iconStyle} />
              </div>

              <div>
                <Typography className={classes.tab_headline}>
                  Pickup Management
                </Typography>
                <Typography className={classes.tab_text}>
                  Schedule an authorised member to pick up a child.
                </Typography>
              </div>
            </Paper>
          </Grid>

          <Grid
            item
            lg={3}
            md={3}
            sm={4}
            xs={8}
            className={`${classes.item5} ${classes.grid_padding_bottom_xs}`}
          >
            <Paper className={`${classes.paper}`}>
              <div className={classes.activity_image_blue}>
                <img src={IconActivity} alt="" className={classes.iconStyle} />
              </div>

              <div>
                <Typography className={classes.tab_headline}>
                  Activity Feed
                </Typography>
                <Typography className={classes.tab_text}>
                  Monitor scheduled activities that children participate in.
                </Typography>
              </div>
            </Paper>

            <Paper className={`${classes.paper}`}>
              <div className={classes.activity_image_red}>
                <img
                  src={IconIncidentReports}
                  alt=""
                  className={classes.iconStyle}
                />
              </div>
              <div>
                <Typography className={classes.tab_headline}>
                  Incident Reports
                </Typography>
                <Typography className={classes.tab_text}>
                  Report child behavior and notify parents accordingly.
                </Typography>
              </div>
            </Paper>
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={4}
            xs={8}
            className={`${classes.tab_grid_padding} ${classes.item6} ${classes.grid_padding_top_xs}`}
          >
            <Paper className={`${classes.paper}`}>
              <div className={classes.activity_image_orange}>
                <img
                  src={IconPhotoAndVideos}
                  alt=""
                  className={classes.iconStyle}
                />
              </div>
              <div>
                <Typography className={classes.tab_headline}>
                  Photos & videos
                </Typography>
                <Typography className={classes.tab_text}>
                  Share interactive media with parents.
                </Typography>
              </div>
            </Paper>

            <Paper className={`${classes.paper} ${classes.paper4}`}>
              <div className={classes.activity_image_green}>
                <img
                  src={IconActivityReports}
                  alt=""
                  className={classes.iconStyle}
                />
              </div>
              <div>
                <Typography className={classes.tab_headline}>
                  Activity Reports
                </Typography>
                <Typography className={classes.tab_text}>
                  Learn about children outcomes after an activity.
                </Typography>
              </div>
            </Paper>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
            className={`${classes.activity_box} ${classes.item7}`}
          >
            <Typography variant="h5" style={{ textAlign: "start" }}>
              Activity Tracking
            </Typography>
            <Typography variant="subtitle1" style={{ textAlign: "start" }}>
              Stay up to date with the latest activities that your children
              engage in. Learn about their behavior and performance while
              implementing new activities to ensure daycare outcomes are met.
            </Typography>
            <Link to="/activity-tracking">
              <Typography
                className={classes.tab_headline2}
                style={{ textAlign: "start" }}
              >
                Learn More{" "}
                <span className={classes.icon_style}>
                  <ArrowForwardIcon />
                </span>
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
          justify="center"
          alignItems="center"
          className={classes.activity_wrapper2}
        >
          <Grid item xs={12} className={classes.item1}>
            <Typography variant="h4">
              Managing your centre has never been this easy
              <br className={classes.hide_br} /> Go digital, achieve excellence
            </Typography>
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            xs={12}
            className={`${classes.activity_box} ${classes.item2}`}
          >
            <Typography variant="h5" style={{ textAlign: "start" }}>
              Center Management
            </Typography>
            <Typography variant="subtitle1" style={{ textAlign: "start" }}>
              Digitizing your childcare center has never been easier. Learn more
              about how we can help you can become the top-performing daycare
              center in your country today.
            </Typography>
            <Link to="/center-management">
              <Typography
                className={classes.tab_headline2}
                style={{ textAlign: "start" }}
              >
                Learn More{" "}
                <span className={classes.icon_style}>
                  <ArrowForwardIcon />
                </span>
              </Typography>
            </Link>
          </Grid>

          <Grid
            item
            lg={3}
            md={3}
            sm={4}
            xs={8}
            className={`${classes.item3} ${classes.grid_padding_bottom_xs}`}
          >
            <Paper className={classes.paper}>
              <div className={classes.activity_image_green}>
                <img
                  src={IconDataManagement}
                  alt=""
                  className={classes.iconStyle}
                />
              </div>
              <div>
                <Typography className={classes.tab_headline}>
                  Data management
                </Typography>
                <Typography className={classes.tab_text}>
                  Monitor data analytics about your center.
                </Typography>
              </div>
            </Paper>
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={4}
            xs={8}
            className={`${classes.item4} ${classes.grid_padding_top_xs}`}
          >
            <Paper className={classes.paper}>
              <div className={classes.activity_image_orange}>
                <img
                  src={IconStaffManagement}
                  alt=""
                  className={classes.iconStyle}
                />
              </div>
              <div>
                <Typography className={classes.tab_headline}>
                  Staff Management
                </Typography>
                <Typography className={classes.tab_text}>
                  Manage teachers, assistants, and other administrators.
                </Typography>
              </div>
            </Paper>

            <Paper className={classes.paper}>
              <div className={classes.activity_image_red}>
                <img
                  src={IconAdministrativeReport}
                  alt=""
                  className={classes.iconStyle}
                />
              </div>
              <div>
                <Typography className={classes.tab_headline}>
                  Administrative reports
                </Typography>
                <Typography className={classes.tab_text}>
                  Use a built-in human resources system.
                </Typography>
              </div>
            </Paper>
          </Grid>

          <Grid
            item
            lg={3}
            md={3}
            sm={4}
            xs={8}
            className={`${classes.item5} ${classes.grid_padding_bottom_xs}`}
          >
            <Paper className={classes.paper}>
              <div className={classes.activity_image_green}>
                <img
                  src={IconAutomatedInvoice}
                  alt=""
                  className={classes.iconStyle}
                />
              </div>
              <div>
                <Typography className={classes.tab_headline}>
                  Automated Invoice
                </Typography>
                <Typography className={classes.tab_text}>
                  Bill clients directly with electronic communication.
                </Typography>
              </div>
            </Paper>

            <Paper className={classes.paper}>
              <div className={classes.activity_image_red}>
                <img
                  src={IconRevenueSummary}
                  alt=""
                  className={classes.iconStyle}
                />
              </div>
              <div>
                <Typography className={classes.tab_headline}>
                  Revenue summary
                </Typography>
                <Typography className={classes.tab_text}>
                  Receive an end-of-month summary about your revenue.
                </Typography>
              </div>
            </Paper>
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={4}
            xs={8}
            className={`${classes.tab_grid_padding} ${classes.item6} ${classes.grid_padding_top_xs}`}
          >
            <Paper className={classes.paper}>
              <div className={classes.activity_image_orange}>
                <img
                  src={IconOnlinePayment}
                  alt=""
                  className={classes.iconStyle}
                />
              </div>
              <div>
                <Typography className={classes.tab_headline}>
                  Online Payment
                </Typography>
                <Typography className={classes.tab_text}>
                  Allow parents and families to pay for their child via the app.
                </Typography>
              </div>
            </Paper>

            <Paper className={classes.paper}>
              <div className={classes.activity_image_blue}>
                <img
                  src={IconChildLedgers}
                  alt=""
                  className={classes.iconStyle}
                />
              </div>
              <div>
                <Typography className={classes.tab_headline}>
                  Child ledgers
                </Typography>
                <Typography className={classes.tab_text}>
                  Understand the cost to manage each child.
                </Typography>
              </div>
            </Paper>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
            className={`${classes.activity_box} ${classes.item7}`}
          >
            <Typography variant="h5" style={{ textAlign: "start" }}>
              Billing
            </Typography>
            <Typography variant="subtitle1" style={{ textAlign: "start" }}>
              Send, receive, and manage your finances in one place. With added
              security, you will be able to control your center’s billing system
              with little concern.
            </Typography>
            <Link to="/billing">
              <Typography
                className={classes.tab_headline2}
                style={{ textAlign: "start" }}
              >
                Learn More{" "}
                <span className={classes.icon_style}>
                  <ArrowForwardIcon />
                </span>
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
          justify="center"
          alignItems="center"
          className={classes.activity_wrapper2}
        >
          <Grid item xs={12} className={classes.item1}>
            <Typography variant="h4">
              Share with parents what matters to them.
              <br className={classes.hide_br} /> Satisfied parents, organization
              growth
            </Typography>
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            xs={12}
            className={`${classes.activity_box} ${classes.item2}`}
          >
            <Typography variant="h5" style={{ textAlign: "start" }}>
              Managing learning path
            </Typography>
            <Typography variant="subtitle1" style={{ textAlign: "start" }}>
              No child is left behind. Create individualistic learning plans for
              each child that is a part of your system.
            </Typography>
            <Link to="/managing-learning-path">
              <Typography
                className={classes.tab_headline2}
                style={{ textAlign: "start" }}
              >
                Learn More{" "}
                <span className={classes.icon_style}>
                  <ArrowForwardIcon />
                </span>
              </Typography>
            </Link>
          </Grid>

          <Grid
            item
            lg={3}
            md={3}
            sm={4}
            xs={8}
            className={`${classes.item3} ${classes.grid_padding_bottom_xs}`}
          >
            <Paper className={classes.paper}>
              <div className={classes.activity_image_orange}>
                <img
                  src={IconAssessment}
                  alt=""
                  className={classes.iconStyle}
                />
              </div>
              <div>
                <Typography className={classes.tab_headline}>
                  Assessment
                </Typography>
                <Typography className={classes.tab_text}>
                  Conduct and record daily child assessments.
                </Typography>
              </div>
            </Paper>

            <Paper className={classes.paper}>
              <div className={classes.activity_image_green}>
                <img
                  src={IconLessonPlaning}
                  alt=""
                  className={classes.iconStyle}
                />
              </div>
              <div>
                <Typography className={classes.tab_headline}>
                  Lesson Planing
                </Typography>
                <Typography className={classes.tab_text}>
                  Individualize learning plans for success.
                </Typography>
              </div>
            </Paper>
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={4}
            xs={8}
            className={`${classes.tab_grid_padding} ${classes.item4} ${classes.grid_padding_top_xs}`}
          >
            <Paper className={classes.paper}>
              <div className={classes.activity_image_blue}>
                <img
                  src={IconDistanceLearning}
                  alt=""
                  className={classes.iconStyle}
                />
              </div>
              <div>
                <Typography className={classes.tab_headline}>
                  Distance Learning
                </Typography>
                <Typography className={classes.tab_text}>
                  Promote remote work to keep children on track.
                </Typography>
              </div>
            </Paper>

            <Paper className={classes.paper}>
              <div className={classes.activity_image_red}>
                <img
                  src={IconDigitalReport}
                  alt=""
                  className={classes.iconStyle}
                />
              </div>
              <div>
                <Typography className={classes.tab_headline}>
                  Digital Report
                </Typography>
                <Typography className={classes.tab_text}>
                  Digitally access learning results for each child.
                </Typography>
              </div>
            </Paper>
          </Grid>

          <Grid
            item
            lg={3}
            md={3}
            sm={4}
            xs={8}
            className={`${classes.item5} ${classes.grid_padding_bottom_xs}`}
          >
            <Paper className={classes.paper}>
              <div className={classes.activity_image_blue}>
                <img
                  src={IconDedicatedApp}
                  alt=""
                  className={classes.iconStyle}
                />
              </div>
              <div>
                <Typography className={classes.tab_headline}>
                  Dedicated App
                </Typography>
                <Typography className={classes.tab_text}>
                  Access KinderCubby on any mobile device.
                </Typography>
              </div>
            </Paper>

            <Paper className={classes.paper}>
              <div className={classes.activity_image_red}>
                <img
                  src={IconMonitorChildsGrowth}
                  alt=""
                  className={classes.iconStyle}
                />
              </div>
              <div>
                <Typography className={classes.tab_headline}>
                  Monitor Child’s Growth
                </Typography>
                <Typography className={classes.tab_text}>
                  Ensure your child is developing physically and emotionally.
                </Typography>
              </div>
            </Paper>
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={4}
            xs={8}
            className={`${classes.tab_grid_padding} ${classes.item6} ${classes.grid_padding_top_xs}`}
          >
            <Paper className={classes.paper}>
              <div className={classes.activity_image_orange}>
                <img
                  src={IconInstantMessaging}
                  alt=""
                  className={classes.iconStyle}
                />
              </div>
              <div>
                <Typography className={classes.tab_headline}>
                  Instant Messaging
                </Typography>
                <Typography className={classes.tab_text}>
                  Quickly communicate with teachers.
                </Typography>
              </div>
            </Paper>

            <Paper className={classes.paper}>
              <div className={classes.activity_image_green}>
                <img
                  src={IconShareEveryMoment}
                  alt=""
                  className={classes.iconStyle}
                />
              </div>
              <div>
                <Typography className={classes.tab_headline}>
                  Sharing every moment
                </Typography>
                <Typography className={classes.tab_text}>
                  Don’t miss out on your child’s youth.
                </Typography>
              </div>
            </Paper>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
            className={`${classes.activity_box} ${classes.item7}`}
          >
            <Typography variant="h5" style={{ textAlign: "start" }}>
              Parents engagement
            </Typography>
            <Typography variant="subtitle1" style={{ textAlign: "start" }}>
              Keep parents engaged in their child’s journey more than ever.
              KinderCubby deliver exceptional digital technology to keep daycare
              centers and families connected on all levels.
            </Typography>
            <Link to="/parents-engagements">
              <Typography
                className={classes.tab_headline2}
                style={{ textAlign: "start" }}
              >
                Learn More{" "}
                <span className={classes.icon_style}>
                  <ArrowForwardIcon />
                </span>
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Container>

      <Typography
        variant="h4"
        className={`${classes.h4} ${classes.slider_text1}`}
      >
        Customer Stores
      </Typography>

      <SliderImage
        msg={
          "We thoroughly enjoy KinderCubby as both a parent and daycare owner. It allows everyone to stay updated with their child and communicate effectively!"
        }
      />

      <Link to="/case-studies">
        <Typography
          className={`${classes.tab_headline3} ${classes.slider_text2}`}
        >
          More Customer Stories{" "}
          <span className={classes.icon_style}>
            <ArrowForwardIcon />
          </span>
        </Typography>
      </Link>

      <Container maxWidth="lg">
        <Grid container className={`${classes.wrapper}`}>
          <Grid item lg={12} className={classes.background_style}>
            <Typography variant="h4">
              KinderCubby offers modern solution for modern childcare
            </Typography>
            <Typography variant="h6">
              Our platform incoporates a user-friendly experience that allows
              daycare owners, parents, and even children to engage with each
              other anytime, anywhere. It simplifies childcare management for
              families while benefiting childcare centers by increasing their
              service quality capabilities.
            </Typography>
            <div className={classes.center}>
              <Link to="/try-it-free">
                <Button
                  variant="contained"
                  color="primary"
                  className={`${classes.banner_button} button`}
                >
                  Try it Free
                </Button>
              </Link>
              <Link to="pricing">
                <Button
                  variant="outlined"
                  color="primary"
                  className={`${classes.banner_button} ${classes.margin_left_20} button`}
                >
                  Explore Pricing
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default FeatureOverviews;
