import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  makeStyles,
  IconButton,
  Typography,
  Container,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ReCAPTCHA from "react-google-recaptcha";
import HeaderCurveDesignP31 from "../../assets/images/HeaderCurveDesignP31.png";
import logo from "../../assets/images/logo.png";
import Desktop from "../../assets/images/Desktop.png";
import curve from "../../assets/images/curve.svg";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { countryList } from "../../data/CountryList";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import axios from "axios";
import PulseLoader from "react-spinners/PulseLoader";
const useStyle = makeStyles((theme) => ({
  wrapper: {
    backgroundImage: `url(${HeaderCurveDesignP31})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "top",
    marginTop: "-63px",
    justifyContent: "center",
  },

  logo_wrapper: {
    width: "100%",
    textAlign: "center",
    padding: 20,
  },
  input: {
    marginBottom: 0,
  },
  inputField: {
    padding: "5px",
    height: 45,
    borderRadius: 7,
  },
  inputLabel: {
    top: -5,
  },
  left: {
    display: "flex",
    margin: "30px 0px",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
  left_inner: {},
  desktop: {
    maxWidth: "100%",
    marginBottom: 35,
  },

  right: {
    textAlign: "center",
    borderRadius: 12,
    boxShadow: "1px 1px 3px 2px #ddd",
    background: "#fff",
    padding: "40px 60px",
    margin: "30px 90px",
    [theme.breakpoints.down("xs")]: {
      margin: "30px 20px",
      padding: 20,
    },
  },
  right2: {
    textAlign: "center",
    borderRadius: 12,
    boxShadow: "1px 1px 3px 2px #ddd",
    background: "#fff",
    padding: 40,
    margin: "75px 230px 30px",
    [theme.breakpoints.down("lg")]: {
      margin: "75px 120px 30px",
      padding: 20,
    },
    [theme.breakpoints.down("md")]: {
      margin: "75px 80px 30px",
      padding: 20,
    },
    [theme.breakpoints.down("xs")]: {
      margin: "30px 20px",
      padding: 20,
    },
  },
  title: {
    fontSize: "2rem",
    fontWeight: 600,
  },
  title2: {
    fontSize: "2rem",
    fontWeight: 600,
    textAlign: "center",
    position: "relative",
    background: "antiquewhite",
    [theme.breakpoints.down("xs")]: { fontSize: "1.3rem" },
  },
  subtitle: {
    fontSize: "1.4rem",
    // marginBottom: 20,
    [theme.breakpoints.down("xs")]: { fontSize: "1.1rem" },
  },
  subtitle2: {
    fontSize: "0.875rem",
    color: "#464141",
    marginBottom: 20,
    // textAlign: "left",
    fontWeight: 600,
    // color: "#21409a",
    background: "#eaecf3",
    width: "250px",
    padding: "5px",
    borderRadius: "10px",
  },
  icon: {
    position: "relative",
    top: 7,
    marginRight: 5,
  },
  paddingStyle: {
    padding: "45px 50px 45px 50px !important",
    [theme.breakpoints.down("xs")]: {
      padding: "25px 12px 25px 12px !important",
    },
  },
  iconStyle: {
    position: "absolute",
    right: 6,
    top: 0,
    [theme.breakpoints.down("xs")]: {
      right: -9,
      top: -8,
    },
  },
  body2Style: {
    color: "#EE6741 !important",
    [theme.breakpoints.down("xs")]: {
      fontSize: "8px !important",
    },
  },

  message_title: {
    fontSize: "25px",
    fontFamily: '"Inter", sans-serif',
    fontWeight: 400,
    margin: "7px auto 30px auto",
    letterSpacing: "2.5px",
    color: "#70dd88",
    border: "1px solid",
    maxWidth: "400px",
    borderRadius: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      maxWidth: "200px",
    },
  },
  topic_detail_side: {
    fontSize: "12px",
    fontFamily: '"Inter", sans-serif',
    fontWeight: 400,
    color: "#5f6065",
    lineHeight: "20px",
    wordWrap: "break-word",
    textAlign: "left",
    margin: "0 0 20px 0",
  },
  list_container: {
    display: "flex",
    gap: "20px",
    marginBottom: "20px",
    marginLeft: "10px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "10px",
    },
  },
  number_chip: {
    fontSize: "11px",
    border: "1px solid #ddd",
    padding: "7px",
    borderRadius: "25px",
    background: "#eea941",
    fontFamily: '"Inter", sans-serif',
    color: "#e5e5e5",
    fontWeight: 600,
  },
}));

const TryItFree = () => {
  const classes = useStyle();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organisationName, setOrganisationName] = useState("");
  const [phoneNO, setPhoneNO] = useState("");
  const [organisationEmail, setOrganisationEmail] = useState("");
  const [country, setCountry] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [unit, setUnit] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [enrolledNo, setEnrolledNo] = React.useState(10);
  const [loading, setLoading] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [formSubmitSuccessfully, setFormSubmitSuccessfully] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const handleClickOpen = () => {
    let err = validation();
    if (err) {
      return;
    } else {
      setOpen(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const handleChange = (event) => {
    setEnrolledNo(event.target.value);
  };
  const onChange = (value) => {
    setCaptchaValue(value);
  };
  const validation = () => {
    let isError = false;

    if (!organisationName.trim()) {
      handleSnakbarOpen("Please enter organisation name", "error");
      document.getElementById("organisationName").focus();
      return (isError = true);
    }
    if (!phoneNO.trim()) {
      handleSnakbarOpen("Please enter phone number", "error");
      document.getElementById("phoneNO").focus();
      return (isError = true);
    }

    if (!country.trim()) {
      handleSnakbarOpen("Please select a country", "error");
      document.getElementById("country").focus();
      return (isError = true);
    }
    if (!streetAddress.trim()) {
      handleSnakbarOpen("Please enter street address", "error");
      document.getElementById("streetAddress").focus();
      return (isError = true);
    }

    if (!city.trim()) {
      handleSnakbarOpen("Please enter city", "error");
      document.getElementById("city").focus();
      return (isError = true);
    }
    if (!state.trim()) {
      handleSnakbarOpen("Please enter state", "error");
      document.getElementById("state").focus();
      return (isError = true);
    }
    if (!zipCode.trim()) {
      handleSnakbarOpen("Please enter zip code", "error");
      document.getElementById("zipCode").focus();
      return (isError = true);
    }
    if (!firstName.trim()) {
      handleSnakbarOpen("Please enter first name", "error");
      document.getElementById("firstName").focus();
      return (isError = true);
    }
    if (!lastName.trim()) {
      handleSnakbarOpen("Please enter last name", "error");
      document.getElementById("lastName").focus();
      return (isError = true);
    }
    if (!organisationEmail.trim()) {
      handleSnakbarOpen("Please enter email address", "error");
      document.getElementById("organisationEmail").focus();
      return (isError = true);
    } else if (
      !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        organisationEmail.trim()
      )
    ) {
      handleSnakbarOpen("Invalid email address", "error");
      document.getElementById("organisationEmail").focus();
      return (isError = true);
    }
    return isError;
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log("onSubmit captchaValue", captchaValue);

    if (captchaValue === null || captchaValue === "") {
      handleSnakbarOpen("Please select the captcha", "error");

      return;
    } else {
      setLoading(true);

      try {
        let formData = new FormData();

        formData.append("daycareName", organisationName.trim());
        formData.append("firstName", firstName.trim());
        formData.append("lastName", lastName.trim());
        formData.append("contactPersonEmail", organisationEmail.trim());
        formData.append("contactPersonPhone", phoneNO.trim());
        formData.append("country", country.trim());
        formData.append("street", streetAddress.trim());
        formData.append("address", unit.trim());
        formData.append("city", city.trim());
        formData.append("state", state.trim());
        formData.append("postalCode", zipCode.trim());
        formData.append("recaptchaResponse", captchaValue.trim());

        let response = await axios({
          url: "api/v1/public/free-trial",
          method: "post",
          data: formData,
        });
        if (response.status >= 200 && response.status < 300) {
          // handleSnakbarOpen("Successful", "success");
          setFormSubmitSuccessfully(true);
          handleClose();
        }
      } catch (error) {
        console.log("error", error);
        setLoading(false);
        handleSnakbarOpen(error.response.data.message.toString(), "error");
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Container maxWidth="lg">
        <Grid container className={`${classes.wrapper}`}>
          {formSubmitSuccessfully ? (
            <>
              <div className={classes.right2}>
                {/* <img src={curve} alt="" /> */}
                <div>
                  {/* <Grid
                    container
                    direction="row"
                    alignItems="center"
                    style={{ justifyContent: "center" }}
                  >
                    <Grid item>
                     <div className={classes.message_title}>
                      <h2>Success</h2>
                    </div> 
                    </Grid>
                  </Grid> */}
                  <Grid item lg={12} sm={12} className={classes.logo_wrapper}>
                    <Link to="/">
                      <img src={logo} alt="" />
                    </Link>
                  </Grid>
                  <div className={classes.message_title}>
                    Successfully Submitted
                  </div>
                  <div className={classes.topic_detail_side}>
                    Thank you for choosing our Daycare Software for managing
                    your daycare center! We're delighted to have you on board
                    and ready to streamline your daycare management.
                  </div>
                  <div className={classes.topic_detail_side}>
                    Here's what will happen next:
                  </div>

                  <div className={classes.list_container}>
                    <div>
                      <div className={classes.number_chip}>01</div>{" "}
                    </div>
                    <div
                      className={classes.topic_detail_side}
                      style={{ margin: 0 }}
                    >
                      Your onboarding form has been successfully submitted and
                      is being processed by our system. This usually happens
                      instantly, so there's no waiting time.
                    </div>
                  </div>
                  <div className={classes.list_container}>
                    <div>
                      <div className={classes.number_chip}>02</div>{" "}
                    </div>
                    <div
                      className={classes.topic_detail_side}
                      style={{ margin: 0, fontWeight: "bold" }}
                    >
                      You will receive an email from us at the email address you
                      provided. This email will contain your login credentials -
                      your registered email as your username and a
                      system-generated password for your daycare portal.
                    </div>
                  </div>
                  <div className={classes.list_container}>
                    <div>
                      <div className={classes.number_chip}>03</div>{" "}
                    </div>
                    <div
                      className={classes.topic_detail_side}
                      style={{ margin: 0, fontWeight: "bold" }}
                    >
                      Click on the link provided in the email to navigate to the
                      login page of your daycare portal.
                    </div>
                  </div>
                  <div className={classes.list_container}>
                    <div>
                      <div className={classes.number_chip}>04</div>{" "}
                    </div>
                    <div
                      className={classes.topic_detail_side}
                      style={{ margin: 0, fontWeight: "bold" }}
                    >
                      Enter your registered email and the system-generated
                      password to log in.
                    </div>
                  </div>
                  <div className={classes.list_container}>
                    <div>
                      <div className={classes.number_chip}>05</div>{" "}
                    </div>
                    <div
                      className={classes.topic_detail_side}
                      style={{ margin: 0 }}
                    >
                      Once logged in, you'll have full access to manage your
                      daycare related features right from your portal. Although
                      the system-generated password is secure, you have the
                      option to change it to something more memorable if you
                      wish.
                    </div>
                  </div>
                  <div className={classes.topic_detail_side}>
                    Please ensure to check your spam/junk folder if you do not
                    receive the email in your inbox. For any issues or
                    questions, feel free to contact our support team. We're
                    always here to help.
                  </div>
                  <div className={classes.topic_detail_side}>
                    Thank you for choosing us and we look forward to helping you
                    manage your daycare center effectively!
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <Grid item lg={12} sm={12} className={classes.logo_wrapper}>
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>
              </Grid>
              <Grid item lg={5} md={5}>
                <div className={classes.left}>
                  <div className={classes.left_inner}>
                    <img className={classes.desktop} src={Desktop} alt="" />
                    <Typography variant="subtitle1">
                      <CheckIcon className={classes.icon} />
                      Complete Childcare management solution
                    </Typography>
                    <Typography variant="subtitle1">
                      <CheckIcon className={classes.icon} />
                      Activity tracking to ease the work.
                    </Typography>
                    <Typography variant="subtitle1">
                      <CheckIcon className={classes.icon} />
                      Save time, save money, boost organisation
                    </Typography>
                    <Typography variant="subtitle1">
                      <CheckIcon className={classes.icon} />
                      Contactless check-in
                    </Typography>
                    <Typography variant="subtitle1">
                      <CheckIcon className={classes.icon} />
                      Centralised data
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item lg={7} md={12}>
                <div className={classes.right}>
                  <div
                    style={{
                      background: "antiquewhite",
                      // position: absolute,
                      // top: 38px,
                      // width: 418px,
                      // left: 60px,
                      padding: "15px",
                      borderRadius: "10px",
                      boxSizing: "border-box",
                      marginBottom: "20px",
                    }}
                  >
                    <Typography className={classes.title}>
                      Get Started
                    </Typography>
                    <Typography className={classes.subtitle}>
                      Free upto 10 students, 1 stuff
                    </Typography>
                  </div>

                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    style={{ justifyContent: "center" }}
                  >
                    <Grid item>
                      <Typography className={classes.subtitle2}>
                        Organization Info
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Organization Name"
                        variant="outlined"
                        id="organisationName"
                        className={classes.input}
                        InputProps={{
                          className: classes.inputField,
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel,
                        }}
                        value={organisationName}
                        onChange={(e) => setOrganisationName(e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Phone Number"
                        variant="outlined"
                        id="phoneNO"
                        className={classes.input}
                        InputProps={{
                          className: classes.inputField,
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel,
                        }}
                        value={phoneNO}
                        onChange={(e) => setPhoneNO(e.target.value)}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel
                          className={classes.input_font_color}
                          id="demo-simple-select-outlined-label"
                        >
                          Country
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="country"
                          label="Country"
                          IconComponent={ExpandMoreIcon}
                          style={{ textAlign: "left" }}
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        >
                          {/* <MenuItem value="">
                        <em>None</em>
                      </MenuItem> */}
                          {countryList?.map((item, i) => (
                            <MenuItem value={item.name} key={i}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Street Address"
                        variant="outlined"
                        id="streetAddress"
                        className={classes.input}
                        InputProps={{
                          className: classes.inputField,
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel,
                        }}
                        value={streetAddress}
                        onChange={(e) => setStreetAddress(e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Unit / Apt. / Suite"
                        variant="outlined"
                        id="unit"
                        className={classes.input}
                        InputProps={{
                          className: classes.inputField,
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel,
                        }}
                        value={unit}
                        onChange={(e) => setUnit(e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        label="City"
                        id="city"
                        variant="outlined"
                        className={classes.input}
                        InputProps={{
                          className: classes.inputField,
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel,
                        }}
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        label="State"
                        id="state"
                        variant="outlined"
                        className={classes.input}
                        InputProps={{
                          className: classes.inputField,
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel,
                        }}
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Zip Code"
                        id="zipCode"
                        variant="outlined"
                        className={classes.input}
                        InputProps={{
                          className: classes.inputField,
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel,
                        }}
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                      />
                    </Grid>

                    {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel
                      className={classes.input_font_color}
                      id="demo-simple-select-outlined-label"
                    >
                      Number of students enrolled
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="enrolledNo"
                      value={enrolledNo}
                      onChange={handleChange}
                      label="Number of students enrolled"
                      IconComponent={ExpandMoreIcon}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}

                    {/* <Grid item lg={12} sm={12} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="button"
                    onClick={handleClickOpen}
                  >
                    Try it for free
                  </Button>
                </Grid> */}
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    style={{ justifyContent: "center" }}
                  >
                    <Grid item>
                      <Typography
                        className={classes.subtitle2}
                        style={{ marginTop: 20 }}
                      >
                        Contact Person Info
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        label="First Name"
                        id="firstName"
                        variant="outlined"
                        className={classes.input}
                        InputProps={{
                          className: classes.inputField,
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel,
                        }}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Last Name"
                        variant="outlined"
                        id="lastName"
                        className={classes.input}
                        InputProps={{
                          className: classes.inputField,
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel,
                        }}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        id="organisationEmail"
                        className={classes.input}
                        InputProps={{
                          className: classes.inputField,
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel,
                        }}
                        value={organisationEmail}
                        onChange={(e) => setOrganisationEmail(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Button
                    fullWidth
                    style={{ marginTop: "30px" }}
                    variant="contained"
                    color="primary"
                    className="button"
                    onClick={handleClickOpen}
                  >
                    Try it for free
                  </Button>
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
        {/* <div>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div> */}
        <DialogContent className={classes.paddingStyle}>
          <Typography className={classes.title2}>
            Review Information
            <IconButton className={classes.iconStyle} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Typography>
          <br />

          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body2" className={classes.body2Style}>
                Organisation Name
              </Typography>
              <Typography
                variant="body1"
                style={{ textAlign: "left", margin: "0" }}
              >
                {organisationName}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography
                variant="body2"
                className={classes.body2Style}
                style={{ textAlign: "right" }}
              >
                Contact Person
              </Typography>
              <Typography
                variant="body1"
                style={{ textAlign: "right", margin: "0" }}
              >
                {firstName} {lastName}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body2" className={classes.body2Style}>
                Organisation Email
              </Typography>
              <Typography
                variant="body1"
                style={{ textAlign: "left", margin: "0" }}
              >
                {organisationEmail}
              </Typography>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography
                variant="body2"
                className={classes.body2Style}
                style={{ textAlign: "right" }}
              >
                Phone No
              </Typography>
              <Typography
                variant="body1"
                style={{ textAlign: "right", margin: "0" }}
              >
                {phoneNO}
              </Typography>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography
                variant="body2"
                className={classes.body2Style}
                style={{ textAlign: "left" }}
              >
                Country
              </Typography>
              <Typography
                variant="body1"
                style={{ textAlign: "left", margin: "0" }}
              >
                {country}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography
                variant="body2"
                style={{ textAlign: "right" }}
                className={classes.body2Style}
              >
                Street Address
              </Typography>
              <Typography
                variant="body1"
                style={{ textAlign: "right", margin: "0" }}
              >
                {streetAddress}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography
                variant="body2"
                className={classes.body2Style}
                style={{ textAlign: "left" }}
              >
                Unit / Apt. /Suite
              </Typography>
              <Typography
                variant="body1"
                style={{ textAlign: "left", margin: "0" }}
              >
                {unit.length > 0 ? unit : "N/A"}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography
                variant="body2"
                style={{ textAlign: "right" }}
                className={classes.body2Style}
              >
                City
              </Typography>
              <Typography
                variant="body1"
                style={{ textAlign: "right", margin: "0" }}
              >
                {city}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography
                variant="body2"
                className={classes.body2Style}
                style={{ textAlign: "left" }}
              >
                State
              </Typography>
              <Typography
                variant="body1"
                style={{ textAlign: "left", margin: "0" }}
              >
                {state}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography
                variant="body2"
                style={{ textAlign: "right" }}
                className={classes.body2Style}
              >
                Zip Code
              </Typography>
              <Typography
                variant="body1"
                style={{ textAlign: "right", margin: "0" }}
              >
                {zipCode}
              </Typography>
            </Grid>
          </Grid>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ReCAPTCHA
              // ref={recaptchaRef}

              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={onChange}
            />
          </div>
          <br />

          <Button
            fullWidth
            variant="contained"
            color="primary"
            disableElevation
            disabled={loading}
            onClick={onSubmit}
            style={{ minHeight: "37px" }}
          >
            {loading === false && "START MY TRIAL"}
            <PulseLoader
              color={"#ee6741"}
              loading={loading}
              size={10}
              speedMultiplier={0.5}
            />{" "}
          </Button>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default TryItFree;
